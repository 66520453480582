<template>
    <div class="content-component teacher-page column">
        <div class="row">
            <label for="text">{{$t('teacherPage')}}:</label>
            <ckeditor :editor="editor" tag-name="textarea" v-model="teacherPage" :config="editorConfig"></ckeditor>
        </div>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
export default {
    name: 'TeacherPageCard',
    mixins:[BackOfficeComponentMixin], 
    computed:{ 
        teacherPage:{
            get(){
                return this.component.content || "";
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'content')
            }
        },
    },
}
</script>
<style lang="less">

.content-component.teacher-page{
    position: relative;
    width: 704px;
    margin-left: 40px;
    padding: 24px;
    box-sizing: border-box;
    label{
        width: 120px;
    }
    .ck.ck-editor{
        margin-left: 16px;
        margin-top: 0;
        width: 560px;

        .ck-content{
            height: 480px;
        }
    }
}
</style>

